<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>{{ page_name }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <!--内容-->
        <div class="page-content-x">
            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="用户姓名">
                                <el-input v-model="search.actual_name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="手机号">
                                <el-input v-model="search.phone"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                                   @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                                   @click="issearch = !issearch">显示搜索
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table
                    size="medium"
                    border
                    v-loading="loading"
                    :data="tableData"
                    style="width: 100%">
                <el-table-column
                        prop="user_nick"
                        label="用户昵称"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="phone"
                        label="用户手机号"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="actual_name"
                        label="用户姓名"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="card_front"
                        label="身份证"
                        width="100">
                    <template slot-scope="scope">
                        <div>
                            <el-image
                                    style="width: 22px; height: 22px; margin-right: 4px;"
                                    :src="scope.row.card_front"
                                    fit="contain"
                                    :preview-src-list="[scope.row.card_front]">
                            </el-image>
                            <el-image
                                    style="width: 22px; height: 22px; margin-right: 4px;"
                                    :src="scope.row.card_back"
                                    fit="contain"
                                    :preview-src-list="[scope.row.card_back]">
                            </el-image>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="state"
                        label="状态"
                        width="180"
                >
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.state===0" type="info">未审核</el-tag>
                        <el-tag size="mini" v-if="scope.row.state===1" type="success">
                            通过{{ scope.row.audit_time }}
                        </el-tag>
                        <el-tag size="mini" v-if="scope.row.state===2" type="danger">拒绝</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="audit_time"
                        label="审核时间"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="create_time"
                        label="创建时间"
                        width="180">
                </el-table-column>
                <el-table-column
                        fixed="right"
                        label="操作"
                        width="200">
                    <template slot-scope="scope">
                        <el-button
                                v-if="is_auth('approve.coach.getinfo')"
                                @click="to_details(scope.row.certified_uuid)"
                                size="mini">查看详情
                        </el-button>
                        <el-button
                                v-if="is_auth('approve.coach.getinfo') && is_auth('approve.coach.examine') && scope.row.state===0 "
                                @click="on_examine(scope.row)"
                                type="primary" size="mini">审核
                        </el-button>
                        <el-button
                                v-if="is_auth('approve.coach.iscancel') && scope.row.state===1"
                                @click="is_cancel(scope.row)"
                                type="danger" size="mini">取消认证
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination
                    @current-change="getlist"
                    :page-size="this.env.pageSize"
                    :pager-count="7"
                    background
                    layout="prev, pager, next, total"
                    :current-page.sync="page"
                    :total="count">
            </el-pagination>
            <!--审核-->
            <el-dialog v-if="is_auth('approve.coach.examine')"
                       title="教练审核"
                       :visible.sync="examine_view"
                       width="60%"
                       :close-on-press-escape="false"
                       :close-on-click-modal="false"
            >
                <el-row>
                    <el-form size="medium" ref="form" label-width="120px">
                        <el-col :span="24">
                            <el-form-item label="用户昵称">
                                <span class="form-span-text">{{ info.user_nick }}</span>
                            </el-form-item>
                            <el-form-item label="用户姓名">
                                <span class="form-span-text">{{ info.actual_name }}</span>
                            </el-form-item>
                            <el-form-item label="用户手机号">
                                <span class="form-span-text">{{ info.phone }}</span>
                            </el-form-item>
                            <el-form-item label="身份证照片">
                                <el-image
                                        style="width: 160px; height: 120px; margin-right: 4px;"
                                        :src="info.card_front"
                                        fit="contain"
                                        :preview-src-list="[info.card_front]">
                                </el-image>
                                <el-image
                                        style="width: 160px; height: 120px; margin-right: 4px;"
                                        :src="info.card_back"
                                        fit="contain"
                                        :preview-src-list="[info.card_back]">
                                </el-image>
                            </el-form-item>
                            <el-form-item label="审核备注">
                                <el-input
                                        type="textarea"
                                        :rows="2"
                                        v-model="info.audit_remark"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-form>
                </el-row>
                <el-row style="margin: 10px 0px;">
                    <el-button size="medium" type="primary" @click="examine_save(1)">通过</el-button>
                    <el-button size="medium" type="danger" @click="examine_save(2)">拒绝</el-button>
                    <el-button size="medium" @click="examine_view = false">取 消</el-button>
                </el-row>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '教练认证申请',
            loading: true,      // 加载状态
            issearch: false,    // 搜索是否展示
            search: {},         // 搜索内容
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            tableData: [],// 列表内容

            examine_view: false, // 审批页面
            info: {},
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 判断是否为空
        is_empty(auth) {
            return this.Tool.is_empty(auth)
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.search = {
                actual_name: '',
                phone: '',
            }
            this.searchtime = null
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "approve.coach.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 跳转详情
        to_details(certified_uuid) {
            this.$router.push({path: '/approve/coach/details', query: {certified_uuid}})
        },
        // 点击审批
        on_examine(info) {
            this.info = info
            this.examine_view = true
        },
        // 审批
        examine_save(state = 0) {
            let postdata = {
                api_name: "approve.coach.examine",
                token: this.Tool.get_l_cache('token'),
                certified_uuid: this.info.certified_uuid,
                audit_remark: this.info.audit_remark,
                state,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.examine_view = false
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 取消认证
        is_cancel(info) {
            let tip = '取消教练【' + info.user_nick + '】认证？'
            this.$confirm(tip, '确认信息', {})
                    .then(() => {
                        this.is_cancel_a(info.certified_uuid, info.user_nick)
                    })
                    .catch(() => {
                    });
        },
        is_cancel_a(certified_uuid, user_nick) {
            let postdata = {
                api_name: "approve.coach.iscancel",
                token: this.Tool.get_l_cache('token'),
                certified_uuid,
                user_nick,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/*表格不换行*/
>>> .el-table__body .cell {
    white-space: nowrap;
    /*overflow: initial;*/
}

.form-tag_colour-div {
    min-height: 200px;
}

</style>
